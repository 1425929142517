.bag-count-container {
  .bag-count-card {
    .value-wrapper {
      .sub-header {
        font-size: 1em !important;
      }
      .value {
        font-size: 1em !important;
      }
    }
  }
}